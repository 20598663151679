<template>
  <card class="card text-center" style="max-width: 450px;" :style="{width: this.width, height: this.height}" >
    <img class="card-top" v-lazy="this.image+'?w=359&h=200&fit=crop'" width="100%" height="200px">
    <label v-if="this.label" class="badge-pill" style="position:absolute;top:180px;right: 7px;padding: 3px;font-weight: 600;vertical-align: auto;border-radius: 0px"> ● {{this.label}}</label>

    <div class="card-title text-left">
      <h3 class="tulum-party-title" style="font-size: 1.5rem">{{this.title}}</h3>
      <p style="margin-top: -30px;margin-bottom: 20px;font-size: 0.8rem">{{this.subTitle}}</p>
    </div>

    <div class="card-body text-left" style="margin-left: -15px;height: 100%;">
     <div v-html="this.text"> </div>
      <div class="row" style="margin-top: 60px;">
        <div class="col-sm-12">
          <a v-on:click="this.bookTable" class="btn btn-primary btn-sm"  href="#">{{this.actionLabel}}</a>
        </div>

      </div>
    </div>
  </card>
</template>
<script>
export default {
  name: 'ClubCard',
  methods: {
    bookTable()   {
      let url = 'https://wa.me/' + this.contactNumber;
      if (this.code) {
        url += `?text=RSVP::${this.code.toUpperCase()}`;
      }
      window.open(url);
      window.ga('send', 'event', 'reservations', this.code, 'Main', 20);
      this.$rollbar.info(`Club/Card Reservations: ${this.code}`)
    }
  },
  props: {
    title: {
      type: String,
      description: 'Card title',
    },
    code: {
      type: String,
      description: 'Club Code',
    },
    contactNumber: {
      type: String,
      description: 'Contact Number',
      default: '524421867547'
    },
    subTitle: {
      type: String,
      description: 'Card subtitle',
    },
    label: {
      type: String,
      description: 'Card label',
    },
    actionLabel: {
      type: String,
      description: 'Action Label / Button Text',
      default: 'BOOK YOUR TABLE'
    },
    text: {
      type: String,
      description: 'Card text',
    },
    image: {
      type: String,
      description: 'Card image',
    },
    width: {
      type: Number,
      description: 'Card width',
      default: 450
    },
    height: {
      type: Number,
      description: 'Card height',
      default: 700
    }
  },
};
</script>


