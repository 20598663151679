<template>
  <div class="content">
    <hr />
    <vue-whatsapp-widget
      phoneNumber="+524421867547"
      companyName="tulum.party (Gaby)"
      textReply="Typically replies within 10-15 mins (9am - 10pm)"
      companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
    />

    <div class="text-center">
      <div class="header text-center" style="margin-bottom: 10px">
        <h1 class="tulum-party-title" style="letter-spacing: 0.5rem">
          ISLA MUJERES
          <br /><span style="font-size: 0.8rem; margin-top: -10px"
            >Get lost in the party island
          </span>
        </h1>
        <small style="margin-top: -420px"></small>

        <img
          v-lazy="mainImage + '?q=&w=300&h=200&fit=clamp'"
          height="140px"
          width="400px"
        />
        <hr />

        <h5
          class="tulum-party-subtitle"
          style="
            font-size: 18px;
            color: #3ea2c1 !important;
            letter-spacing: 0.5rem;
            font-weight: bold;
            padding: 1px 60px 2px 60px;
          "
        >
          There is a perfect sanctuary in the Riviera Maya to relax, get cozzy,
          and have lots of fun!
        </h5>
        <hr />

        <p style="font-size: 16px">
          <b>Isla Mujeres</b>
          is a beautiful small island across the bay of Mujeres, from Cancún, in
          the Riviera Maya. It is known as a paradise of
          <b
            >pristine coral waters, white sand beaches, and a rich diversity of
            tropical wildlife and vegetation</b
          >. The party atmosphere in Isla Mujeres is unique in all the Riviera
          Maya. Because it is such a small island, everything is naturally
          unique and exclusive. The beach clubs in Isla Mujeres also have a
          magical day and nightlife, compared to the one in Cancun. Isla Mujeres
          has the most natural and untempered beach vibes in all the Riviera.
        </p>
        <br />
      </div>
      <img
        v-lazy="bgPalm"
        v-if="showPalms"
        class="d-inline-sm d-md-none"
        alt="tulum.party crazy palm"
        style="
          position: fixed;
          left: 5%;
          bottom: 5%;
          opacity: 0.1;
          width: 100%;
          z-index: 0;
        "
      />
      <card
        class="card text-center"
        style="border: 1px solid black; background-color: #262626"
      >
        <template slot="header">
          <h3 class="tulum-party-title" style="background-color: #00f2c3">
            HOT CLUBS
          </h3>
          <div class="row">

            <div class="col-md-6">
              <ClubCard
                title="Amazona Beach Club"
                code="amazona_isla"
                contact-number="524421867547"
                action-label="GET YOUR SPOT !"
                sub-title="By Mar-Bella Luxury Hotel Group"
                image="https://imgix.cosmicjs.com/d351c840-f8fd-11ed-8197-a5d57d514ff7-amazona-isla.jpg"
                text="<b>Amazon is an exclusive and private beach club</b> through and through, in there you will be surrounded by nature and you will feel disconnected from everything but the moment, the beach, the <b>music and its amazing drink selection!</b>"
              ></ClubCard>
            </div>

            <div class="col-md-6">
              <ClubCard
                title="Zama Beach Club"
                code="zama_isla"
                contact-number="524421867547"
                label="OCEAN SWING SET"
                action-label="BOOK NOW!"
                sub-title="Award Winning Beach Restaurant and Club"
                image="https://imgix.cosmicjs.com/b7b88150-f8fd-11ed-8197-a5d57d514ff7-zama-isla.jpg"
                text="<b>Zama</b> has been recognized by many international publications as one of the <b>best gourmet restaurants and vibrant clubs</b> in Isla Mujeres. Regarding its ocean views and beaches, Zama will take your breath away!"
              ></ClubCard>
            </div>
            <div class="col-md-6">
              <ClubCard
                title="Kin Ha Beach Club"
                code="kin-ha_isla"
                label="BEACH DOCK"
                action-label="Reserve your Spot!"
                contact-number="524421867547"
                sub-title="Extremely beautiful oceanfront Beach Club!"
                image="https://imgix.cosmicjs.com/98760880-f8fd-11ed-8963-6baf671ee0f3-kinha-isla.jpg"
                text="Kin ha houses one of the best restaurants on the Island, and it is well known as one of the most relaxed and luxury beach clubs in Isla Mujeres. <b>Book your table today!</b>"
              ></ClubCard>
            </div>
            <div class="col-md-6">
              <ClubCard
                title="Taboo Isla Mujeres"
                code="taboo_isla"
                contact-number="524421867547"
                action-label="INFORMATION"
                label="Temporally Closed "
                sub-title="Temporally Closed"
                text="Taboo Isla Mujeres, Will Open Soon. Check <a href='https://www.tulum.party/tulum-clubs/taboo-tulum'>Taboo Tulum</a> as an alternative"
                image="https://imgix.cosmicjs.com/deaf0630-f529-11ed-a7ff-83a764999c5f-image.png"
              ></ClubCard>
              <!--                text="<b>Taboo</b> is one of the most renowned and prestigious beach clubs in the continent, either in Cancun, Tulum, Cabo or Isla Mujeres, Taboo is always the precise choice of beach party vibes. Every now and then Taboo is ta place."-->

            </div>
          </div>
          <p>Want to talk with an expert?</p>
          <hr />
          <a
            href="https://wa.me/524421867547?text=INFO_ISLA_MUJERES"
            v-on:click="onQuickReserveClick()"
            class="btn font-weight-bold"
            style="
              background-color: #00f2c3 !important;
              color: black !important;
              border: 1px solid silver;
              z-index: 2;
            "
          >
            📲 INFO & RSVP
          </a>
          <br />
          <br />
          <a href="mailto:bookings+islamujeres@tulum.party"
            >bookings+islamujeres@tulum.party</a
          >
          <br />

          <small> We operate from 8AM - 11PM / Answer within 15 mins</small>
          <br />
          <small style="color: #fff">NO EXTRA FEE</small>
          <br />
          <br />
        </template>
      </card>
      <div
        class="text-center"
        style="
          padding: 10px;
          background-color: #3ea2c1;
          border: 1px solid #6ecf6e;
        "
        v-if="islaParties.length"
      >
        <h4
          style="
            background-color: black;
            margin-top: 1px;
            font-weight: bold;
            font-size: 1.6rem;
            margin-bottom: -10px;
            text-transform: uppercase;
          "
          class="tulum-party-subtitle;"
        >
          Upcoming Events
        </h4>
        <br />
        <time-line>
          <time-line-item
            v-for="(event, idx) in islaParties"
            :key="event._id"
            :inverted="idx % 2 === 1"
            :badge-type="badgeType(idx)"
          >
            <div slot="content">
              <EventCard
                type="IslaEvent"
                v-bind:event="event"
                :key="event._id"
                :idx="idx"
              ></EventCard>
            </div>
          </time-line-item>
        </time-line>
      </div>
    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from 'src/components';
import EventCard from '../../components/EventCard/EventCard';
import moment from 'moment';
import ClubCard from '@/components/Cards/ClubCard';
const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
  );

export default {
  name: 'IslaMujeres',
  created: function () {
    setTimeout(() => (this.showPalms = true), 5000);
    this.$store.dispatch('getIslaParties');
  },

  props: ['slug'],

  metaInfo() {
    return {
      title:
        'Isla Mujeres: Top Clubs, Events & Parties. Get lost in the party island.',
      meta: [
        {
          name: 'description',
          content: `Tickets & Information for the best events in Isla Mujeres`,
        },
        {
          name: 'keywords',
          content: `Isla Mujeres, Parties, Riviera Maya, Top Clubs, Beach Clubs, Events`,
        },
        {
          property: 'og:title',
          content: `Top Events & Parties in Isla Mujeres`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/isla-mujeres-party`,
        },
        {
          property: 'og:description',
          content: `Event Tickets & Club Reservations @ Isla Mujeres Mexico`,
        },
        { property: 'og:type', content: 'article' },
      ],
    };
  },
  data: () => {
    return {
      showPalms: false,
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=3EA2C1,black&duotone-alpha=100&auto=format',
      mainImage:
        'https://imgix.cosmicjs.com/b4f11670-244b-11ee-982a-ed7f706ddfc5-image.png',
    };
  },
  components: {
    TimeLine,
    VueWhatsappWidget,
    ClubCard,
    TimeLineItem,
    EventCard,
  },

  methods: {
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    onQuickReserveClick() {
      window.ga(
        'send',
        'event',
        'reservations',
        'Isla info request',
        'Main',
        20
      );
      this.$rollbar.info('IslaMujeres Info request');
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  computed: {
    islaParties: {
      get() {
        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');

          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...this.$store.state.islaParties]
          .sort((a, b) => {
            return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
              moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      },
    },
  },
};
</script>
